.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

/* Modify default material ui behavior */
.MuiDataGrid-root .MuiDataGrid-cell {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.MuiDataGrid-viewport,.MuiDataGrid-row,.MuiDataGrid-renderingZone{
  max-height: fit-content!important;
}
.MuiDataGrid-cell{
  max-height:fit-content!important;
  overflow:auto;
  max-height: inherit;
  white-space: initial!important;
  line-height:16px!important;
  display:flex!important;
  align-items: center;
  padding-top: 10px!important;
  padding-bottom: 10px!important;

}